
import React, { useState, useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BsBoxArrowLeft } from "react-icons/bs";
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logout from '@mui/icons-material/Logout';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
//import { RoutesContext } from "../App";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import '../App.css'
import Popper from "@mui/material/Popper";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdMenu, MdDarkMode, MdLightMode, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BsSearch } from "react-icons/bs"
import { VscTriangleDown } from "react-icons/vsc";
import { PiArrowLeftBold } from "react-icons/pi";
import { useNavigate, Link, NavLink, useLocation, useParams } from "react-router-dom";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import { VscLayoutSidebarLeft } from "react-icons/vsc";

import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Avatar from '@mui/material/Avatar';

import { SidebarContext } from "../App";

import { LuLogOut } from "react-icons/lu";


import { loginRequest, b2cPolicies } from "../authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//const { newRoutes, setRoutes } = useContext(RoutesContext);

const ListItemButton = styled(MuiListItemButton)({
    '&.active .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#1E40AF',
        backgroundColor: '#EFF6FF',
    },
});


const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

///MOBILE MUI THEMES







function AnonSidebar() {

    const { instance, accounts } = useMsal();

    const [logoutMode, setLogoutMode] = useState(false)

    const {
        sidebarTitle,
        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        sidebarUserRole,
        setSidebarUserRole,
        clientSearchData,
        setSidebarActiveUser,
        sidebarActiveUser,
        sidebarCurrentUserTheme,
        sidebarLogoutMode,
        setSidebarLogoutMode
    } = useContext(SidebarContext);


    const activeUserData = sidebarActiveUser;

    function getAccountBadge() {

        const account = accounts[0]?.idTokenClaims;

        const firstName = account?.given_name;
        const lastName = account?.family_name;

        const initials = firstName?.slice(0, 1) + lastName?.slice(0, 1);
        //const initials = "CT";
        /* console.log("initials " + initials);*/

        return {
            sx: {
                fontSize: '14px',
                bgcolor: sidebarCurrentUserTheme,
                color: '#FFFFFF',
            },
            children: `${initials}`,
        };

    }

    //ClientSearchPopper functions
    /* console.log("Within Sidebar: ClientSearchData:", clientSearchData);*/



    const [clientSearchFilterInput, setClientSearchFilterInput] = useState('');
    const [filteredClients, setFilteredClients] = useState([])
    const [displayFileredClients, setDisplayFilteredClients] = useState(false);



    function handleResetAllClientFilters() {
        setClientSearchFilterInput('');
        setFilteredClients([]);



    }



    const searchClients = (searchValue) => {
        setClientSearchFilterInput(searchValue)



        if (clientSearchFilterInput !== '') {
            const filteredData = clientSearchData.filter((item) => {
                return item.auditName.toLowerCase().includes(clientSearchFilterInput.toLowerCase())
            })
            /* console.log("FOUND in Search Filtered Clients: ", filteredData);*/
            setFilteredClients(filteredData);


        } else {
            setFilteredClients(clientSearchData)

        }



    }



    const params = useParams();



    /*  console.log("Sidebar Current PARAMS: ", params);*/



    const location = useLocation();

    const currentRouteLocation = location.pathname;

    let trimmedCurrentRouteLocation = currentRouteLocation.slice(1)
    const capitalizedTrimmedCurrentRouteLocation = trimmedCurrentRouteLocation.charAt(0).toUpperCase() + trimmedCurrentRouteLocation.slice(1);
    /*  console.log("currentRouteLocation,", currentRouteLocation)*/

    const [topbarLocation, setTopbarLocation] = useState("")



    //from NavigationBar.jsx

    const isAuthenticated = useIsAuthenticated();

    const [visible, setVisible] = useState(false);
    const [isActive, setActive] = useState(false);






    const handleLogout = () => {
        setSidebarLogoutMode(true);
        instance.logoutRedirect()
    }


    // Material UI provided hooks
    const navigate = useNavigate();



    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);
    const openAccountMenu = Boolean(accountMenuAnchorEl);
    const handleOpenAccountMenu = (event) => {
        setAccountMenuAnchorEl(event.currentTarget);

    };
    const handleCloseAccountMenu = () => {
        setAccountMenuAnchorEl(null);

    };

    const handleDrawerToggleListItemDesktop = () => {
        setOpen(!open);
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    //custom NavLink component wrapper to utilize active class styling within MUI ListItem component
    const NavLinkWrapper = React.forwardRef((props, ref) => (
        <NavLink ref={ref} key={props.item?.id} {...props} className={props.item?.isActive ? ('active-navLink border-bottom__thick-blue') : (" ")} tag={Link} to={props.item?.path} style={{ textDecoration: 'none' }} />

    ));

    //For SwitchClient popper
    const [switchClientAnchorEl, setSwitchClientAnchorEl] = React.useState(null);

    const handleOpenSwitchClientPopper = (event) => {
        setSwitchClientAnchorEl(switchClientAnchorEl ? null : event.currentTarget);
    };

    const handleCloseSwitchClientPopper = () => {
        setSwitchClientAnchorEl(null);
        setDisplayFilteredClients(false);
    };

    const openSwitchClientPopper = Boolean(switchClientAnchorEl);

    return (
        <>

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <AppBar elevation={1} position="fixed" open={open} sx={{ backgroundColor: '#FFFFFF', shadowColor: '#E5E5E5' }}>
                    <Toolbar position="sticky">

                        <Avatar src={bmilogo} variant="square" sx={{
                            ml: '-1rem',
                            mr: '2rem',
                            height: 42,
                            minWidth: 42,
                            padding: 0,
                            color: '#23549F',
                            marginRight: 0,
                            ...(open && { display: 'none' }),
                        }} />

                        {open ? (<></>) : (<Divider orientation="vertical" variant="middle" flexItem sx={{ ml: '0.9rem' }} />)}




                        
                           
                                <div className="spacer-margin-left">
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={20} width={52} />
                            </SkeletonTheme>
                                </div>

                               

                          
                        
                       
                       

                        <Box
                            style={{ marginRight: "auto" }}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mr: 5 }}
                        >
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton circle={true} height={40} width={40} />
                            </SkeletonTheme>
                            {/*<Avatar*/}
                            {/*    alt=""*/}
                            {/*    sx={{*/}
                            {/*        fontSize: '14px',*/}
                            {/*        bgcolor: sidebarCurrentUserTheme,*/}
                            {/*        color: '#FFFFFF',*/}
                            {/*    }}>*/}
                            {/*    .*/}
                            {/*</Avatar>*/}

                            {/*{isAuthenticated ? (<>*/}
                            {/*    <Tooltip title="Account settings">*/}
                            {/*        <IconButton*/}
                            {/*            onClick={handleOpenAccountMenu}*/}
                            {/*            size="small"*/}
                            {/*            sx={{ ml: 2 }}*/}
                            {/*            aria-controls={openAccountMenu ? 'account-menu' : undefined}*/}
                            {/*            aria-haspopup="true"*/}
                            {/*            aria-expanded={openAccountMenu ? 'true' : undefined}*/}
                            {/*        >*/}
                            {/*            {sidebarLogoutMode ? (<>*/}
                            {/*                <Avatar*/}
                            {/*                    alt=""*/}
                            {/*                    sx={{*/}
                            {/*                        fontSize: '14px',*/}
                            {/*                        bgcolor: sidebarCurrentUserTheme,*/}
                            {/*                        color: '#FFFFFF',*/}
                            {/*                    }}>*/}
                            {/*                    .*/}
                            {/*                </Avatar>*/}
                            {/*            </>) : (<>*/}
                            {/*                <Avatar {...getAccountBadge()} />*/}
                            {/*            </>)}*/}

                            {/*            */}{/*  <Typography sx={{ fontSize: '14px' }} >*/}

                            {/*            */}{/* </Typography>*/}
                            {/*            */}{/*  </Avatar>*/}
                            {/*        </IconButton>*/}
                            {/*    </Tooltip></>) : (<></>)}*/}

                        </Box>

                        <Box
                            style={{ marginLeft: "auto", position: 'sticky' }}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 0, display: { xs: "flex", md: "none" }, mr: 5 }}>
                            <Tooltip title="Open Navigation menu">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0, color: "#23549F", fontWeight: "bold", }}
                                >
                                    <MdMenu />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >







                                <MenuItem>
                                    <Typography textAlign="center" color="#23549F">
                                        BTN A
                                    </Typography>
                                </MenuItem>

                                <MenuItem>
                                    <Typography textAlign="center" color="#23549F">
                                        BTN B
                                    </Typography>
                                </MenuItem>





                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} PaperProps={{
                    sx: {
                        backgroundColor: '#FFFFFF'
                    }
                }} >
                    <DrawerHeader>
                        <Avatar src={bmilogo} variant="square" sx={{ mr: 'auto' }} />

                    </DrawerHeader>
                    <Divider style={{ background: '#E5E5E5' }} />
            


                    <List
                        style={{ marginTop: 'auto' }}
                    >
                        <ListItem key={'vNumberDesktop'} disablePadding sx={{ display: 'block' }}>


                            <ListItemText
                                primaryTypographyProps={{ fontSize: '12px' }}
                                primary={"Version: 1.0.1"} sx={{ ml: '1rem', fontWeight: '400', color: '#525252', justifyContent: open ? 'initial' : 'center', opacity: open ? 1 : 0 }} />


                        </ListItem>

                        <Divider style={{ background: '#FFFFFF' }} />
                        <Tooltip title={open ? ('Close Sidebar') : ('Open Sidebar')}>
                            <ListItem disablePadding sx={{
                                display: 'block',
                            }}
                            >
                                <ListItemButton
                                    aria-label={open ? ('Close Sidebar') : ('Open Sidebar')}
                                    onClick={handleDrawerToggleListItemDesktop}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        "&.Mui-selected": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":hover": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":active": {
                                            backgroundColor: '#EFF6FF'
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{



                                            color: 'inherit',
                                            minWidth: 0,
                                            ml: open ? 'auto' : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {open ? (<MdKeyboardDoubleArrowLeft style={{
                                            height: '24px',
                                            width: '24px',
                                            color: '#1E40AF'
                                        }} />) : (<MdKeyboardDoubleArrowRight style={{
                                            height: '24px',
                                            width: '24px',
                                        }} />)}

                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                      

                    </List>
                </Drawer>
            </Box>


          

           
        </>
    );
}

export default AnonSidebar;

