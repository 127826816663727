
import React, { useContext } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { format, formatISO } from 'date-fns'



import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';

import { ProjectDashboardContext } from '../../App';


function AuditEndDateMenu({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent, graphData, graphTableData }) {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const {
  
        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates
    } = useContext(ProjectDashboardContext);

    function renderDate(date) {
        let changedDate = null;
        if (!date) {
            return '';
        }
            changedDate = format(new Date(date), 'MM/dd/yyyy');
            if (changedDate == '12/12/1800') {
                changedDate = 'ALL';
            }
        
        return changedDate;
    }

    console.log("Available Client End Dates from Menu: ", availableClientAuditEndDates);
    console.log("Active Client End Dates from Menu: ", activeClientAuditEndDates);

    const navigate = useNavigate();

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        console.log("passed in value from Select: ", value);
        let lastValue = value[value.length - 1];
        console.log("last value passed in from Select", lastValue)
        /*let existingActiveList = activeClientAuditEndDates;*/
        let newMainActiveList = activeClientAuditEndDates;
        const newValue = { auditEndDate: lastValue };

        newMainActiveList = [...newMainActiveList,  newValue  ];

        /* let nuValue = { auditEndDate: value };*/
        // On autofill we get a stringified value.
        /*typeof nuValue?.value === 'string' ? nuValue.split(',') : nuValue,*/

        setPersonName(
           newValue
        );
        handleUpdateActiveClientAuditEndDates(newMainActiveList);
    };

    function handleDelete(value){
       
        console.log("PersonName Pre DELETE", activeClientAuditEndDates );
        const newActiveList = activeClientAuditEndDates.filter((i)=> i.auditEndDate !== value)

        console.log("newPersonName Post DELETE", newActiveList);
        handleUpdateActiveClientAuditEndDates(newActiveList);
        setPersonName(
            newActiveList
        );
    };

    

    return (
        <>
            <div>
                <FormControl variant="standard"  sx={{ m: 1, minWidth: '5VW', width: 'fit-content' }}>
                    <InputLabel id="demo-multiple-chip-label">Active Date</InputLabel>
                    
                    <Select
                        disableUnderline
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={activeClientAuditEndDates.map((value) =>  value?.auditEndDate)}
                        onChange={handleChange}
                        input={null}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {activeClientAuditEndDates.map((value) => (
                                    <Chip onMouseDown={e => {
                                        e.stopPropagation();
                                    }} key={value?.auditEndDate} label={renderDate(value?.auditEndDate)} onDelete={() => handleDelete(value?.auditEndDate)} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                       
                    >
                        {availableClientAuditEndDates.map((item) => (
                            <MenuItem
                                key={item?.auditEndDate}
                                value={item?.auditEndDate}
                               
                            >
                                {renderDate(item?.auditEndDate)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    );
}

export default AuditEndDateMenu;

